import React from 'react';
import CardItemExternal from './CardItemExternal';
import './Cards.css'
import resume from '../images/resume.jpeg'

function CardsResume() {
  return (
    <div className='cards'>
      <h1> My resume is linked below, just in case you missed it :)</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <a href='https://drive.google.com/file/d/1gI7ghPcvxhn88xWmKWo2W7-mutfBlfuw/view?usp=sharing'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal
                    src={resume}
                    text='Click here for the latest version of my resume!'
                    label='Resume'
                    path='/resume'
                />
                </a>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsResume;
