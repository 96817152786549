import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <div className='social-media-wrap'>
            <h1> Ryan Liang </h1>
            <div className='footer-logo'>
                <a href='https://www.facebook.com/ryan.liang.9847/'
                target='_blank'
                rel="noopener noreferrer"
                >
                    <i className='fab fa-facebook-f' />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href='https://www.linkedin.com/in/liang-ry/'
                target='_blank'
                rel="noopener noreferrer"
                    >
                    <i class='fab fa-linkedin' />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href='https://github.com/ryanl35'
                target='_blank'
                rel="noopener noreferrer"
                    >
                    <i class='fab fa-github' />
                </a>
            </div>
        </div>
      </section>
      {/* <section className='social-media'>
      </section> */}
    </div>
  );
}

export default Footer;
