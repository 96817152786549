import React from 'react';
import Navbar from './components/Navbar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Projects from './components/pages/Projects';
import Experience from './components/pages/Experience';
import Resume from './components/pages/Resume';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

// import PDF from './components/PDF'
// import resumepdf from './resume.pdf';

function App() {

  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path='/' exact element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/experience' element={<Experience/>}/>
          <Route path='/resume' element={<Resume/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
