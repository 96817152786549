import React from 'react';

function CardItem(props) {
  return (
    <>
        <li className='cards__item'>
            <div className='cards__item__link' to={props.path} >
                <figure className='cards__item__pic-wrap' data-category={props.label}>
                    <img src={props.src} alt='Travel Pic'
                    className='cards__item__img'/>       
                </figure>
                <div className='cards__item__info'>
                    <h3 className='cards__item__title'>{props.title}</h3>
                    <h5 className='cards__item__text'>{props.text}</h5>
                </div>
            </div>
        </li>
    </>
  );
}

export default CardItem;
