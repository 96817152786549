import React from 'react'
import '../../App.css'
import CardsProjects from '../CardsProjects'

export default function Projects() {
    return (
        <>
            <div>
                <h1 className='projects'>PROJECTS</h1>
            </div>
            <CardsProjects />
        
        </>
    )
}