import React from 'react'
import '../../App.css'
import CardsExperience from '../CardsExperience'

export default function Experience() {
    return (
        <>
            <div>
                <h1 className='experience'>EXPERIENCE</h1>;
            </div>
            <CardsExperience />
        
        </>
    )
}