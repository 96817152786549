import React from 'react'
import '../../App.css'
import CardsAbout from '../CardsAbout'

export default function About() {
    return (
        <>
            <div>
                <h1 className='about'>ABOUT</h1>
            </div>
            <CardsAbout />
    
        </>
    )
}