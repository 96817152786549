import React from 'react';
import CardItemExternal from './CardItemExternal';
import './Cards.css'
import grad from '../images/grad-friends.jpg'
import rocket from '../images/rocket-grass.jpeg'
import portland from '../images/portland.jpeg'
import me from '../images/about-back-me.jpeg'

function CardsAbout() {
  return (
    <div className='cards'>
      <h1> Hello!</h1>
      <br />
      <h2 className='bio'> 
        My name is Ryan Liang. I am a 22 year old Data Scientist/Engineer. 
        I am a recent graduate of Northeastern University with a B.S. in Data Science and a double minor in Mathematics and Marketing Analytics.
        I have had many jobs both in the industry and out, and have worked on many projects developing my coding and data science skills!
        </h2>
        <br />
        <h2 className='bio'>
        On the side, I have a strong passion for film! I love watching films for both enjoyment and critique. I took a screenwriting class during college, and my dream is to one day write a fully fleshed-out screenplay.
        Get me started talking about film or TV, and I won't be able to stop. My favorite film (currently) is Everything Everywhere All at Once.
        </h2>
        <br />
        <h2 className='bio'>
            Feel free to reach out to me at my socials below!
        </h2>
        <br />
      <div className='cards__container'>
        <div className='cards_wrapper'>
            <CardItemExternal 
              src={me}
              text='A picture of me on campus!'
              label='Me'
              path='/about'
            />
        </div>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItemExternal 
                    src={grad}
                    text='I just graduated from Northeastern University in 2022!'
                    label='Education'
                    path='/about'
                />
                <CardItemExternal 
                    src={portland}
                    text='I am originally from New Jersey! I have lived in several states, ranging from Massachusetts to Oregon. My dream is to live in California one day!'
                    label='General'
                    path='/about'
                />
                <CardItemExternal 
                    src={rocket}
                    text='I have the best Goldendoodle in the world'
                    label='Puppy'
                    path='/about'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsAbout;
