import React from 'react';
import CardItem from './CardItem';
import './Cards.css'
import foocycle from '../images/foocycle.jpeg';
import esports from '../images/mobile-esports.webp'

function Cards() {
  return (
    <div className='cards'>
      <h1> Learn more about me below!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItem 
                    src={foocycle}
                    text='MongoDB based community project promoting food sustainability'
                    label='Projects'
                    path='/projects'
                />
                <CardItem 
                    src={esports}
                    text='Co-op position at mobile esports gaming platform as a Data Scientist'
                    label='Experience'
                    path='/experience'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
