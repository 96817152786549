import React from 'react';
// import CardItem from './CardItem';
import CardItemExternal from './CardItemExternal';
import './Cards.css'
import code from '../images/code.jpg'
import foocycle from '../images/foocycle.jpeg';
import airline from '../images/airline.webp'
import beekeeping from '../images/beekeeping.jpeg'
import doodlejump from '../images/doodle-jump.jpeg'
import satellite from '../images/satellite.jpeg'
import floodit from '../images/floodit.jpeg'
import spreadsheet from '../images/spreadsheet.png'
import football from '../images/football.jpeg'

function CardsProjects() {
  return (
    <div className='cards'>
      <h1> My projects are linked below!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <a href='https://github.com/ryanl35/ds4420-final-project'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal 
                    src={code}
                    text='Self-led ML project analyzing a mass shootings database'
                    label='Machine Learning'
                    path='/projects'
                />
                </a>
                <a href='https://github.com/ryanl35/DS4300_homework/tree/master/Project'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal
                    src={foocycle}
                    text='MongoDB based community project promoting food sustainability'
                    label='Large-Scale Database Systems'
                    path='/projects'
                />
                </a>
                <a href='https://github.com/ryanl35/analyzing-flight-data'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal 
                    src={airline}
                    to='/'
                    target='_blank'
                    text='Group ML project predicting flight delays based on airline data'
                    label='Machine Learning'
                    path='/projects'
                />
                </a>
            </ul>
        </div>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <a href='https://github.com/ryanl35/urban-beekeeping-project'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal 
                    src={beekeeping}
                    text='Webpage to visualize client hive data for beekeeping lab in Roxbury, Massachusetts'
                    label='Data Visualization'
                    path='/projects'
                />
                </a>
                <a href='https://github.com/ryanl35/cs3520-final-project'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal 
                    src={doodlejump}
                    text='Recreation of popular mobile video game Doodle Jump in C++'
                    label='C++'
                    path='projects'
                />
                </a>
                <a href='https://github.com/ryanl35/Satellite-Collision-Threat-Classification'
                target='_blank'
                rel="noopener noreferrer"
                >
                <CardItemExternal 
                    src={satellite}
                    text='Unsupervised ML project classifying different bodies in space and analyzing threat levels'
                    label='Machine Learning'
                    path='projects'
                />
                </a>
            </ul>
        </div>
        <div className='cards__wrapper'>
        <h3> School Projects that require repo to remain private </h3>
            <br />
            <ul className='cards__items'>
                <CardItemExternal 
                    src={floodit}
                    text='Recreation of popular game "Flood It"'
                    label='Java'
                    path='/projects'
                />
                <CardItemExternal 
                    src={spreadsheet}
                    text={<>Recreation of Microsoft Excel</>}
                    label='Java'
                    path='/projects'
                />
                <CardItemExternal 
                    src={football}
                    text='Basic queryable NFL Database'
                    label='SQL and Python'
                    path='/projects'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsProjects;
