import React from 'react';
import CardItemExternal from './CardItemExternal';
import './Cards.css'
import oncohealth from '../images/oncohealth-1.webp';
import skillz from '../images/skillz-1.webp';
import genmab from '../images/genmab-1.jpeg';
import starbucks from '../images/starbucks.webp';
import cf from '../images/cheesecake-factory.webp';
import og from '../images/og.webp';
import tutor from '../images/tutor.webp';
import library from '../images/library.jpeg';
import mechanic from '../images/mechanic.jpeg';

function CardsExperience() {
  return (
    <div className='cards'>
      <h1> Below are the various places I've worked at!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <h3> Technical / Industry Experience </h3>
            <br />
            <ul className='cards__items'>
                {/* <a href='https://oncohealth.us/'
                target='_blank'
                rel="noopener noreferrer"
                > */}
                <CardItemExternal 
                    src={oncohealth}
                    title='OncoHealth - Associate Data Engineer'
                    text='Analytical oncology company working with large amounts of health insurance data'
                    label='May 2022 - Present'
                    path='/experience'
                />
                {/* </a> */}
                {/* <a href='https://www.skillz.com/'
                target='_blank'
                rel="noopener noreferrer"
                > */}
                <CardItemExternal 
                    src={skillz}
                    title='Skillz, Inc. - Data Science Co-op'
                    text='E-sports gaming platform working with large volume player and gaming data'
                    label='Sept. 2021 - Dec. 2021'
                    path='/experience'
                />
                {/* </a> */}
                {/* <a href='https://www.genmab.com/'
                target='_blank'
                rel="noopener noreferrer"
                > */}
                <CardItemExternal 
                    src={genmab}
                    title='Genmab - Data Science Intern'
                    text='Oncology research company working on building Machine Learning models'
                    label='May 2021 - Aug. 2021'
                    path='/experience'
                />
                {/* </a> */}
            </ul>
        </div>
        <div className='cards__wrapper'>
            <h3> Other Work Experience </h3>
            <br />
            <ul className='cards__items'>
                <CardItemExternal 
                    src={starbucks}
                    title='Starbucks - Barista'
                    text='Barista at Starbucks in downtown Boston'
                    label='Dec. 2021 - May 2022'
                    path='/experience'
                />
                <CardItemExternal 
                    src={cf}
                    title='Cheesecake Factory - Server'
                    text='Server at Cheesecake Factory in downtown Boston'
                    label='Sept. 2020 - Apr. 2021'
                    path='/experience'
                />
                <CardItemExternal 
                    src={tutor}
                    title='Northeastern University - Peer Tutor'
                    text='On-campus tutor specializing in many math and data science related classes'
                    label='Sept. 2020 - Aug. 2021'
                    path='/experience'
                />
            </ul>
        </div>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItemExternal 
                    src={og}
                    title='Olive Garden - Server/Busser/Host'
                    text='Server at Olive Garden for many years in different states'
                    label='June 2017 - Mar. 2020'
                    path='/experience'
                />
                <CardItemExternal 
                    src={library}
                    title='Piscataway Public Library - Library Page'
                    text='Library Page shelving and keeping library organized'
                    label='Sept. 2015 - Aug. 2016'
                    path='/experience'
                />
                <CardItemExternal 
                    src={mechanic}
                    title='Interstate Tires - Mechanic'
                    text='Part-time mechanic at auto-repair shop in Miami, FL'
                    label='June 2015 - Aug. 2015'
                    path='/experience'
                />
            </ul>
        </div>
      </div>
    </div>
  );
}

export default CardsExperience;
