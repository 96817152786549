import React from 'react'
import '../../App.css'
import CardsResume from '../CardsResume'

export default function Resume() {
    window.open('https://drive.google.com/file/d/1gI7ghPcvxhn88xWmKWo2W7-mutfBlfuw/view?usp=sharing');

    // return null;
    return (
        <>
            <div>
                <h1 className='resume'>RESUME</h1>
            </div>
            <CardsResume />
        
        </>
    )
}